import { QueryBuilderClassNames } from "hss-query-builder"
import { environment } from "src/environments/environment"

export const ROLES = {
    'ADMIN': 'Admin', 
    'SUBSCRIBER': 'Subscriber',
    'STYLIST': 'Stylist',
    'ALL': 'All',
    'NONE': 'None',
    'VISITOR': 'Visitor',
    'INFLUENCER': 'Influencer',
    'SUPER_USER': 'SuperUser',
    'PARTNER': 'Partner',
    'PARTNER_ADMIN': 'Partner_Admin'
}

export const APP_CONFIG = {
    INTRO_PAGE: '/internal/you-are-in',
    DEBOUNCE_INTERVAL_LARGE: 1300,
    DEBOUNCE_INTERVAL_MEDIUM: 1000,
    DEBOUNCE_INTERVAL_SHORT: 700,
    DEBOUNCE_INTERVAL: 200,
    ROUTES: {
        
    },
    FIREBASE: {
        BASE_URL: 'https://firebasestorage.googleapis.com/v0/b/qatchcode-production.appspot.com/o/'
    },
    TWILIO: {
        SMS_SHORT_CODE: 30422,
        SMS_TEXT: 'Hi Qatch, verify my number please!',
        INTERVAL: 10000 // 10 sec
    },        
    DATES: {
        DATE_FORMAT: 'yyyy-MM-dd',
        TIMESTAMP_FORMAT: 'yyyy-MM-dd HH:mm',
        MONTH_TIMESTAMP_FORMAT: 'MMM d, y, h:mm a'
    }
}

export const enum OTP_INTENTS {
    SIGNUP = 'SIGNUP',
    RESET_PASSWORD = 'RESET_PASSWORD'
}

export const MESSAGES = {
    SCHEDULE_DATE: `Schedule date should be atleast ${environment.schedule_hrs_in_advance} hr in advance.`
}

export const QUERY_BUILDER_CLASSES: QueryBuilderClassNames = {
    removeIcon: 'fa fa-solid fa-trash',
    addIcon: 'fa fa-plus',
    // arrowIcon: 'fa fa-chevron-right px-2',
    // button: 'btn',
    // buttonGroup: 'btn-group',
    // rightAlign: 'order-12 ml-auto',
    // switchRow: 'd-flex px-2',
    // switchGroup: 'd-flex align-items-center',
    // switchRadio: 'custom-control-input',
    // switchLabel: 'custom-control-label',
    // switchControl: 'custom-control custom-radio custom-control-inline',
    // row: 'row p-2 m-1',
    // rule: 'border',
    // ruleSet: 'border',
    // invalidRuleSet: 'alert alert-danger',
    // emptyWarning: 'text-danger mx-auto',
    // operatorControl: 'form-control',
    // operatorControlSize: 'col-auto pr-0',
    // fieldControl: 'form-control',
    // fieldControlSize: 'col-auto pr-0',
    // entityControl: 'form-control',
    // entityControlSize: 'col-auto pr-0',
    // inputControl: 'form-control',
    // inputControlSize: 'col-auto'
  }

  export const PRODUCT_CLICK_COUNT = "PRODUCT_CLICK_COUNT"
  export const PRODUCT_REACTION_CLICK_COUNT = "PRODUCT_REACTION_CLICK_COUNT"