<div class="modal-content">
    <div class="modal-body  p-md-2 p-3 overflow-auto ">
        <button type="button" class="close" (click)="onClose()">&times;</button>
       <div class="text-center pt-3">
        <div class="title home-heading">Welcome Back!</div>
        <p class="title fs-5 text-bold home-description vpt-1 ">Sign in to save and revisit all of your favorites.</p>
        <p class="sub-description ">Not a member? <a class="a-link" (click)="openSignupPopup()">Sign up Here!</a></p>
       </div>
        <div class="row m-0 p-0 py-2 px-md-5">
            <q-dform [isNew]="true" [fields]="formFields" [formData]="{}"></q-dform>
        </div>
        <div id="captcha-container"></div>
        <div class="text-center vmt-2 vmb-3 mx-auto">
            <q-button [disabled]="uikitFormComponent?.form?.invalid || loading || otpRequested" 
              title="START SHOPPING" (click)="requestOTP($event);" [loading]="loading || otpRequested">
            </q-button>
        </div>
    </div>

</div>