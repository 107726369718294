export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyB9MrOmI_nOzBfERTDDa-sClTCn5WyQVPM',
    authDomain: 'qatchcode-production.firebaseapp.com',
    projectId: 'qatchcode-production',
    storageBucket: 'qatchcode-production.appspot.com',
    messagingSenderId: '119553287765',
    appId: '1:119553287765:web:c0477a5a3421a8759596dc',
    measurementId: 'G-MR5J5N8N8B'
  },
  magicLink: {
    API_KEY: 'pk_live_49CF428AF3A72F58'
  },
  success_toast_delay: 3500,
  error_toast_delay: 5000,
  CSV_FILE_ROWS_LIMIT: 3000,
  waitlist_uri: '/signup',
  WAEV_FORM_KEY: "2033150e-4fb5-4a4c-a885-a0ada4c5ca9c", 
  WAEV_API_KEY: "2F55F76848B903E0451EE6A4645AD571",
  baseUrl: 'https://joinqatch.com/api',
  video_tutorial: 'https://www.youtube.com/embed/w6Hlip4WGXw?loop=1',
  schedule_hrs_in_advance: 2
}
