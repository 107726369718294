import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { lastValueFrom, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { ViewTableService, ViewTablePagedData, ViewTablePage } from '../../../shared/modules/data-view-table/view-table.service';
import { AppService } from 'src/app/shared/services/app.service'
import { CAMPAIGN_STATUS } from './campaigns/config.const';
import { SEGMENT_STATUS } from './segments/config.const';
import { HttpService } from '@hss-m/uikit-common';
import { BaseService } from '../common/classes/base.service';

export interface ICampaign {
  name?: string,
  campaign_id?: number,
  scheduled_at?: Date,
  description?: string,
  status?: CAMPAIGN_STATUS,
  segments?: string[]
  texts?: ICampaignMessage[],
  type: any,
  goal?: any
}

export enum CAMPAIGN_TYPE {
  GENERAL = "GENERAL",     
  COLLECTION = "COLLECTION",      //Collections link sent as part of Campaign
  RETAILER = "RETAILER",          //Brand Promotion Campaign
  HYBRID = "HYBRID"               //Hybrid Campaign
}

export enum CampaignRecurringFrequency{
  ONCE = "once", //Only Once
  DAILY = "daily", //Campaigns recur every day.
  WEEKLY = "weekly", //Campaigns recur on specific days of the week.
  BI_WEEKLY = "bi-weekly", //Campaigns recur every two weeks.
  MONTHLY = "monthly", //Campaigns recur on the same day of each month.
  YEARLY = "yearly", //Campaigns recur on the same date each year.
  CUSTOM_INTERVAL = "custom-interval", //Allow users to set a custom interval, specifying the number of days, weeks, months, or years between recurrences.
  WEEKDAYS = "weekdays", //Recur only on weekdays (Monday to Friday).
  WEEKENDS = "weekends", //Recur only on weekends (Saturday and Sunday).
  SPECIFIC_DAYS_OF_MONTH = "specific-days-of-month" //Allow users to choose specific days of the month for recurrence.';
}

export interface ICampaignMessage {
  text?: string,
  text_order?: number,
  campaign_id?: number,
  is_sale_alert?: boolean,
  track_clicks?: boolean,
  track_reactions?: boolean,
  collection?: string[],
  retailer?: string[],
  media_url?: string
}

export interface ISegment {
  name?: string,
  segment_id?: number,
  campaign_id?: number,
  description?: string,
  rules?: any,
  status?: SEGMENT_STATUS
}

export interface ICollection {
  name?: string,
  shop_title?: string,
  description?: string,
  type?: any,
  slug?: string,
  stylist?: any,
  stylist_id?: number,
  order?: number,
  published?: boolean,
  logo?: any,
}

@Injectable({
  providedIn: 'root'
})
export class StylistsService extends BaseService {

  constructor(
    override readonly http: HttpClient,
    private viewTableService: ViewTableService,
    override readonly appService: AppService,
    override readonly httpService: HttpService
  ) {
    super(http, appService, httpService)
  }

  getCampaignBaseUrl() {
    return `${this.dashboardBaseUrl}/stylist/campaigns`;
  }

  getSegmentBaseUrl() {
    return `${this.dashboardBaseUrl}/stylist/segments`;
  }

  getCollectionBaseUrl() {
    return `${this.dashboardBaseUrl}/stylist/collections`;
  }

  getInventoryBaseUrl() {
    return `${this.dashboardBaseUrl}/stylist/inventory/sql`;
  }

  getRequestsBaseUrl() {
    return `${this.dashboardBaseUrl}/stylist/inbound-requests`;
  }

  getRequests(searchString: string | number | boolean, paramsData: any): Observable<any> {
    const params = new HttpParams().set('searchString', searchString).set('limit', paramsData.limit || '100')
      .set('dir', paramsData.dir).set('prop', paramsData.prop).set('uid', paramsData.uid);
    return this.http.get(`${this.getRequestsBaseUrl()}`, { params });
  }
  updateRequestFulfilledStatus(system_id: number, data: { fulfilled: boolean }) {
    return this.http.patch(`${this.getRequestsBaseUrl()}/${system_id}`, data);
  }

  getStylistUsers(page: Partial<ViewTablePage>, sort: any, filters: {}): Observable<ViewTablePagedData> {
    return this.viewTableService.getData(`${this.appBaseUrl}/stylist/users`, page, sort, filters);
  }

  getAdditionalInfo(uid: any): Observable<any> {
    return this.http.get(`${this.appBaseUrl}/stylist/users/${uid}/additional-info`);
  }

  getInventoryUsers(searchString: string | number | boolean): Observable<any> {
    const params = new HttpParams().set('searchString', searchString).set('limit', '100');
    return this.http.get(`${this.appBaseUrl}/inventory/users`, { params }).pipe(
      map((res: any) => {
        return res.users;
      })
    );
  }

  saveStyleComment(uid: any, style_comments: string): Observable<any> {
    return this.http.post(`${this.appBaseUrl}/stylist/users/${uid}/style_comments`, { style_comments });
  }

  addToUsersQueues(data: { products: any[]; text_date: any; users?: any[]; additional_text?: null; queue_size?: number }): Observable<any> {
    return this.http.post(`${this.dashboardBaseUrl}/stylist/users/add-to-queue`, data);
  }

  createCollection(data: ICollection) {
    return this.http.post(this.getCollectionBaseUrl(), data);
  }

  updateCollection(collection_id: number, data: ICollection) {
    return this.http.patch(`${this.getCollectionBaseUrl()}/${collection_id}`, data);
  }

  cloneCollection(collectionId: number) {
    return this.http.post(`${this.getCollectionBaseUrl()}?cloneOf=${collectionId}`, {});
  }

  deleteCollection(collectionId: number) {
    return this.http.delete(`${this.getCollectionBaseUrl()}/${collectionId}`);
  }

  addToCollection(collectionId: number, data: any): Observable<any> {
    return this.http.post(`${this.getCollectionBaseUrl()}/${collectionId}/products`, data);
  }

  removeFromCollection(collectionId: number, data: any): Observable<any> {
    return this.http.delete(`${this.getCollectionBaseUrl()}/${collectionId}/products`, { body: data });
  }

  fillAdditionalText(data: { text_date: null; additional_text: string; all_users: boolean; users: any[]; stylists: any[] | null }): Observable<any> {
    return this.http.post(`${this.appBaseUrl}/stylist/users/fill-additional-text`, data);
  }

  generateMagicLinks(data: { text: string; all_users: boolean; users: any[]; stylists: any[] | null }): Observable<any> {
    return this.http.post(`${this.appBaseUrl}/stylist/users/generate-magic-links`, data);
  }

  toggleStockCheck(product_id: any, data: { clear: boolean }): Observable<any> {
    return this.http.patch(`${this.getInventoryBaseUrl()}/${product_id}/stock-check`, data);
  }

  deQueue(uid: any, text_id: any): Observable<any> {
    return this.http.patch(`${this.appBaseUrl}/stylist/users/${uid}/qatches/${text_id}/dequeue`, {});
  }

  deleteFromQueue(uid: any, text_id: any): Observable<any> {
    return this.http.delete(`${this.appBaseUrl}/stylist/users/${uid}/qatches/${text_id}/dequeue`);
  }

  updateQueue(text_id: any, data: any) {
    return this.http.patch(`${this.appBaseUrl}/stylist/users/queue/${text_id}`, data);
  }

  downloadFile(url, payload, cb, fileName = 'Inventory_Products.csv') {
    this.http.post(`${this.getInventoryBaseUrl()}/download`, payload, { responseType: 'blob' })
      .subscribe({
        next: (response: Blob) => {
          const blob = new Blob([response], { type: 'application/octet-stream' });
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(blob);
          downloadLink.download = fileName;
          downloadLink.click();
          if (cb) cb(true);
        },
        error: err => {
          if (cb) cb(false);
        }
      });
  }

  updateProduct(product_id: any, data: { bucket?: any; style_group?: any; discount_price?: any; availability_id?: any; age_limit_id?: any; favorite?: boolean }) {
    return this.http.patch(`${this.getInventoryBaseUrl()}/${product_id}`, data);
  }

  deleteQueue(queues: any[]) {
    return this.http.delete(`${this.appBaseUrl}/stylist/users/queues?queues_to_be_deleted=${queues.join(',')}`);
  }

  onStylistUpdated(uid: any, stylist_id: number) {
    return this.http.patch(`${this.appBaseUrl}/stylist/users/${uid}/stylist`, { stylist_id });
  }

  onUserStatusUpdated(uid: any, activity_status_id: number) {
    return this.http.patch(`${this.appBaseUrl}/stylist/users/${uid}`, { activity_status_id });
  }

  createCampaign(data: ICampaign) {
    return this.http.post(this.getCampaignBaseUrl(), data);
  }

  sendTestMessage(data) {
    return this.http.post(`${this.getCampaignBaseUrl()}?sendTest=true`, data);
  }

  sendPublish(data) {
    return this.http.post(`${this.getCampaignBaseUrl()}?sendProductRecs=true`, data);
  }

  updateCampaign(campaign_id: number, data: ICampaign) {
    return this.http.patch(`${this.getCampaignBaseUrl()}/${campaign_id}`, data);
  }

  cloneCampaign(campaign_id: number) {
    return this.http.post(`${this.getCampaignBaseUrl()}?cloneOf=${campaign_id}`, {});
  }

  deleteCampaign(campaign_id: number) {
    return this.http.delete(`${this.getCampaignBaseUrl()}/${campaign_id}`);
  }

  cancelCampaign(campaign_id: number) {
    return this.http.get(`${this.getCampaignBaseUrl()}/${campaign_id}/cancel`);
  }

  forceCampaign(campaign_id: number, action: string) {
    return this.http.get(`${this.getCampaignBaseUrl()}/${campaign_id}/force/${action}`);
  }

  getCampaignStats(campaign_id: number): Observable<{ summary: any }> {
    return this.http.get<{ summary: any[] }>(`${this.getCampaignBaseUrl()}/${campaign_id}/summary`);
  }

  getCampaignSegments(campaign_id: number): Observable<{ segments: any[] }> {
    return this.http.get<{ segments: any[] }>(`${this.getCampaignBaseUrl()}/${campaign_id}/segments`);
  }

  getCampaignTexts(campaign_id: number): Observable<{ texts: any[] }> {
    return this.http.get<{ texts: any[] }>(`${this.getCampaignBaseUrl()}/${campaign_id}/texts`);
  }

  createSegment(data: ISegment) {
    return this.http.post(this.getSegmentBaseUrl(), data);
  }

  updateSegment(segmentId: number, data: ISegment) {
    return this.http.patch(`${this.getSegmentBaseUrl()}/${segmentId}`, data);
  }

  cloneSegment(segmentId: number) {
    return this.http.post(`${this.getSegmentBaseUrl()}?cloneOf=${segmentId}`, {});
  }

  deleteSegment(segmentId: number) {
    return this.http.delete(`${this.getSegmentBaseUrl()}/${segmentId}`);
  }

  addToInventory(data: any) {
    return this.http.post(`${this.dashboardBaseUrl}/stylist/inventory/bigquery/etl`, data);
  }

  async apexList(data: any) {
    return await lastValueFrom(this.http.post(`${this.dashboardBaseUrl}/stylist/inventory/bigquery/apex`, data)) as Promise<any>;
  }

  sendPublishBigQuery(url: string, data: any) {
    return this.http.post(url, data);
  }

  createBigqueryProduct(url: string, data: any) {
    return this.http.post(url, data);
  }

  updateBigqueryProduct(url: string, data: any) {
    return this.http.put(url, data);
  }

  updateProducts(formData: any) {
    return this.http.patch(this.getInventoryBaseUrl() + '/batch-update', formData);
  }

  createAttachCampaign(data: any) {
    return this.http.post(`${this.getRequestsBaseUrl()}/attach-campaign`, data);
  }

  bulkProductCreate(url: string, formData: any) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.http.post(url + '/bulk-create', formData, { headers });
  }

  getUrlMeta(url: string) {
    return this.http.get(url);
  }

  apiUrl(): string {
    return this.appBaseUrl;
  }
}

