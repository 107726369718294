import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {  Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { QuizService } from 'src/app/shared/services/quiz.service';
import { LocalStorageService, STORAGE_CONSTANTS } from 'src/app/shared/services/storage.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UserService } from 'src/app/shared/services/user.service';
import { PhoneUtil } from 'src/app/shared/utils/phone-utils';
import { QATCH_CONSTS } from 'src/app/shared/utils/qatch-constants';

@Component({
    selector: 'app-create-account',
    templateUrl: './create-account.component.html',
    styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit {

    createAccountForm: FormGroup;
    loading = false;

    constructor(
        protected fb: FormBuilder,
        protected userService: UserService,
        private router: Router,
        private toastService: ToastService,
        private quizService: QuizService,
        private storageService: LocalStorageService,
        private authService: AuthService
    ) { }

    ngOnInit(): void {
        const isPhoneVerified = this.quizService.readPhoneVerified();
        if (isPhoneVerified) {
            this.createAccountForm = this.fb.group({
                password: ['', [ Validators.required, Validators.pattern(QATCH_CONSTS.PASSWORD_REGEX) ]],
                c_password: ['', [ Validators.required, Validators.minLength(6) ]],
                email: ['', [ Validators.nullValidator, Validators.email ]],
                promo_code: ['', []]
            });
        } else {
            this.previousPage();
        }
    }

    async submit() {
        if (this.createAccountForm.valid) {
            this.loading = true;
            try {
                const value = this.createAccountForm.value;
                const result = await this.userService.createFirebaseUser({
                    c_password: value.c_password,
                    email: this.nonNullNorEmpty(value.email),
                    password: value.password,
                    promo_code: this.nonNullNorEmpty(value.promo_code)
                }).toPromise();
                await this.authService.logOut();
                this.toastService.success('You Qatch account has been created. Please login to see your Qatches.');
                this.quizService.clearAll();
                this.createAccountForm.reset();
                this.storageService.setObject(STORAGE_CONSTANTS.QATCH_EXPERIENCE_ALERT, true);
                await this.authService.loginWithUser(PhoneUtil.emailFromPhone(result.phone_number), value.password.trim());
                this.storageService.storeInAppCache(STORAGE_CONSTANTS.JUST_LOGGED_IN_EVENT, 'true');
            } catch (error) {
                this.toastService.error(error.error.error);
            } finally {
                this.loading = false;
            }
        }
    }

    nonNullNorEmpty(arg) {
        return arg && arg.length > 0 ? arg : null;
    }

    previousPage() {
        this.router.navigate(['/phone-number']);
    }

    get password() { return this.createAccountForm.get('password'); }
    get c_password() { return this.createAccountForm.get('c_password'); }
    get email() { return this.createAccountForm.get('email'); }
    get promo_code() { return this.createAccountForm.get('promo_code'); }
}
